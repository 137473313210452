var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-spin", { attrs: { spinning: _vm.spinning } }, [
    _c(
      "div",
      { staticClass: "my-task", style: _vm.backCss },
      [
        _vm.taskList.length
          ? _c(
              "div",
              {
                staticClass: "left",
                staticStyle: { display: "flex", "flex-direction": "column" },
              },
              [
                _vm.taskList.findIndex(
                  (item) => item.taskId == _vm.taskDetail.taskId
                )
                  ? _c("a-icon", {
                      style: _vm.isDesktop()
                        ? "font-size:30px"
                        : "font-size:15px",
                      attrs: { type: "left-circle", theme: "filled" },
                      on: { click: _vm.prev },
                    })
                  : _vm._e(),
                _vm.taskList.findIndex(
                  (item) => item.taskId == _vm.taskDetail.taskId
                )
                  ? _c(
                      "span",
                      {
                        style: _vm.isDesktop()
                          ? "font-size:25px"
                          : "font-size:12px",
                      },
                      [_vm._v(_vm._s(_vm.taskDetail.chapterSequenceNo - 1))]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.taskList.length
          ? _c(
              "div",
              {
                staticClass: "right",
                staticStyle: { display: "flex", "flex-direction": "column" },
              },
              [
                _vm.taskList.findIndex(
                  (item) => item.taskId == _vm.taskDetail.taskId
                ) !=
                _vm.taskList.length - 1
                  ? _c("a-icon", {
                      style: _vm.isDesktop()
                        ? "font-size:30px"
                        : "font-size:15px",
                      attrs: { type: "right-circle", theme: "filled" },
                      on: { click: _vm.next },
                    })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    style: _vm.isDesktop()
                      ? "font-size:25px"
                      : "font-size:12px",
                  },
                  [_vm._v(_vm._s(_vm.taskDetail.chapterSequenceNo - 0 + 1))]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "a-row",
          [
            _c(
              "a-breadcrumb",
              { attrs: { separator: ">" } },
              [
                _c(
                  "a-breadcrumb-item",
                  { staticStyle: { "font-size": "16px" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.skip },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.params.isMyTask
                              ? "我的任务"
                              : _vm.taskDetail.productionName ||
                                  _vm.taskDetail.foreignName
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "a-breadcrumb-item",
                  { staticStyle: { "font-size": "16px" }, attrs: { href: "" } },
                  [
                    _vm._v(
                      "\n          任务详情" +
                        _vm._s(
                          _vm.taskDetail && _vm.taskDetail.chapterSequenceNo
                            ? " - " + _vm.taskDetail.chapterSequenceNo
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.taskDetail.productionName
          ? _c(
              "a-row",
              [
                _c(
                  "a-button-group",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "9px",
                      right: "108px",
                      "z-index": "1",
                      display: "flex",
                    },
                  },
                  [
                    _vm.taskDetail.stageName == "给稿"
                      ? _c(
                          "a-tooltip",
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v("\n            上传原稿\n          "),
                            ]),
                            _c(
                              "a-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoDetail(_vm.taskDetail)
                                  },
                                },
                              },
                              [_c("a-icon", { attrs: { type: "upload" } })],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.taskDetail.applyReview &&
                    (_vm.taskDetail.stageName == "制作" ||
                      _vm.taskDetail.stageName == "制作修改" ||
                      _vm.taskDetail.stageName == "监制" ||
                      _vm.taskDetail.stageName == "监制确认" ||
                      _vm.taskDetail.stageName.indexOf("建模") > -1 ||
                      _vm.taskDetail.stageName.indexOf("精草") > -1 ||
                      _vm.taskDetail.stageName.indexOf("勾线") > -1 ||
                      _vm.taskDetail.stageName.indexOf("上色") > -1 ||
                      _vm.taskDetail.stageName.indexOf("后期") > -1)
                      ? _c(
                          "a-tooltip",
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v("\n            上传完成稿\n          "),
                            ]),
                            _c(
                              "a-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoSumbmit(_vm.taskDetail)
                                  },
                                },
                              },
                              [_c("a-icon", { attrs: { type: "upload" } })],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    (_vm.taskDetail.stageName == "监制确认" ||
                      _vm.taskDetail.stageName == "监制" ||
                      _vm.taskDetail.stageName == "交稿" ||
                      _vm.taskDetail.stageName == "勾线终审" ||
                      _vm.taskDetail.stageName == "上色终审") &&
                    _vm.taskDetail.applyReview
                      ? _c(
                          "a-dropdown",
                          { attrs: { disabled: _vm.spinning } },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadOver(false)
                                      },
                                    },
                                  },
                                  [_vm._v("下载终稿 ")]
                                ),
                                _vm.taskDetail.departName.indexOf("日语部") > -1
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadOver(true)
                                          },
                                        },
                                      },
                                      [_vm._v("下载切片\n            ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              [_c("a-icon", { attrs: { type: "download" } })],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.taskDetail.stageName == "交稿" &&
                    _vm.taskDetail.applyTranslation
                      ? _c(
                          "a-dropdown",
                          { attrs: { disabled: _vm.spinning } },
                          [
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadJpg()
                                      },
                                    },
                                  },
                                  [_vm._v("下载翻译JPG ")]
                                ),
                                _vm.taskDetail.departName.indexOf("日语部") > -1
                                  ? _c(
                                      "a-menu-item",
                                      {
                                        key: "2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.downloadPdf()
                                          },
                                        },
                                      },
                                      [_vm._v("下载翻译PDF\n            ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              [_c("a-icon", { attrs: { type: "download" } })],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.taskDetail.stageName == "制作" &&
                        _vm.taskDetail.systemTypes.some(
                          (item) => item == "IMAGE_TRANSLATION_SYSTEM"
                        )
                      ? _c(
                          "a-tooltip",
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v("\n            下载制作稿\n          "),
                            ]),
                            _c(
                              "a-button",
                              {
                                attrs: { loading: _vm.btnLoading },
                                on: { click: _vm.downloadMake },
                              },
                              [_c("a-icon", { attrs: { type: "download" } })],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    ((_vm.taskDetail.taskStatus != "等待" &&
                      _vm.taskDetail.taskStatus != "驳回") ||
                      (_vm.taskDetail.taskStatus == "驳回" &&
                        _vm.taskDetail.rejectTaskId)) &&
                    _vm.taskDetail.applyReview
                      ? _c(
                          "a-dropdown",
                          { attrs: { transitionName: "", trigger: ["click"] } },
                          [
                            _c(
                              "a-button",
                              {
                                on: {
                                  mouseover: function ($event) {
                                    _vm.checkColor = _vm.color
                                  },
                                  mouseout: function ($event) {
                                    _vm.checkColor = "rgba(0, 0, 0, 0.45)"
                                  },
                                },
                              },
                              [
                                _c("checklist", {
                                  staticStyle: {
                                    position: "relative",
                                    top: "2px",
                                    left: "3px",
                                  },
                                  attrs: {
                                    theme: "outline",
                                    size: "21",
                                    fill: _vm.checkColor,
                                    strokeWidth: 3,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-menu",
                              { attrs: { slot: "overlay" }, slot: "overlay" },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "0",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToFatchStraw(true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              加载锁定文件\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "1",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToFatchStraw(false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              不加载锁定文件\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    ((_vm.taskDetail.taskStatus != "等待" &&
                      _vm.taskDetail.taskStatus != "驳回") ||
                      (_vm.taskDetail.taskStatus == "驳回" &&
                        _vm.taskDetail.rejectTaskId)) &&
                    _vm.taskDetail.applyTranslation
                      ? _c("a-button", { on: { click: _vm.goToTranslate } }, [
                          _c("span", {
                            staticClass: "iconfont icon-fanyi icon",
                            staticStyle: {
                              color: "rgba(0,0,0,0.65)",
                              "font-size": "18px",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("actions-tasks-btn", {
                  staticStyle: {
                    position: "absolute",
                    top: "9px",
                    right: "0px",
                    "z-index": "1",
                  },
                  attrs: {
                    isMytaskNew: true,
                    propsParams: _vm.taskDetail,
                    loadData: _vm.reloadData,
                    taskList: _vm.taskDetail.taskList,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "a-row",
          [
            _c(
              "a-col",
              {
                staticStyle: { "padding-top": "5vh" },
                attrs: { span: _vm.isDesktop() ? 10 : 24 },
              },
              [
                _c("a-row", [
                  _c(
                    "div",
                    {
                      staticClass: "task_info",
                      style: _vm.isDesktop()
                        ? "height:70vh;font-size:14px;overflow-y: overlay;padding-top: 4px;"
                        : "font-size:14px;padding-top: 4px;",
                    },
                    [
                      _c("g-title", { staticStyle: { position: "relative" } }, [
                        _vm._v("基本信息"),
                      ]),
                      _vm.checkPermission("production:task:button:edit")
                        ? _c(
                            "a-button",
                            {
                              staticStyle: {
                                position: "absolute",
                                top: "16px",
                                right: "3vw",
                              },
                              attrs: { type: "primary", icon: "edit" },
                              on: { click: _vm.handleClickFormEdit },
                            },
                            [_vm._v(_vm._s(this.isEdit ? "退出编辑" : "编辑"))]
                          )
                        : _vm._e(),
                      !_vm.isEdit
                        ? _c("div", [
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("平台名称")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(
                                  _vm._s(_vm.taskDetail.platformName || "")
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("作品名称")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(
                                  _vm._s(_vm.taskDetail.productionName || "")
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("章节序号")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.taskDetail.chapterSequenceNo) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("章节标题")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(_vm._s(_vm.taskDetail.chapterName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("结算数量")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.getCalcUnits(_vm.taskDetail.calcUnits)
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("当前阶段")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(_vm._s(_vm.taskDetail.stageName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("当前人员")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _c("img", {
                                  style: _vm.isDesktop()
                                    ? "width:30px;height:30px;border-radius:50%;margin-right:10px;"
                                    : "width:24px;height:24px;border-radius:50%;margin-right:10px;",
                                  attrs: {
                                    src: _vm.taskDetail.user
                                      ? _vm.taskDetail.user.avatar
                                      : "",
                                    alt: "",
                                  },
                                }),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.taskDetail.user
                                        ? _vm.taskDetail.user.userName
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("截止日期")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(_vm._s(_vm.deadline)),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("结算月份")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(_vm._s(_vm.settlementDate)),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("时间间隔")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(_vm._s(_vm.taskDetail.timeInterval)),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-info-item" }, [
                              _c("div", [_vm._v("完成情况")]),
                              _c("div", { staticClass: "task-info-content" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.taskDetail.finishStatus == "ON_TIME"
                                        ? "按时"
                                        : _vm.taskDetail.finishStatus ==
                                          "ADVANCE"
                                        ? "提前"
                                        : _vm.taskDetail.finishStatus == "DELAY"
                                        ? "拖稿"
                                        : ""
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ])
                        : _c(
                            "a-form-model",
                            {
                              ref: "taskForm",
                              attrs: { model: _vm.taskDetail },
                            },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "平台名称" },
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请选择平台",
                                      },
                                      model: {
                                        value: _vm.taskDetail.platformId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.taskDetail,
                                            "platformId",
                                            $$v
                                          )
                                        },
                                        expression: "taskDetail.platformId",
                                      },
                                    },
                                    _vm._l(_vm.platformList, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.platformId,
                                          attrs: { value: item.platformId },
                                        },
                                        [_vm._v(_vm._s(item.platformName))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "作品名称" },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.taskDetail.productionName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "productionName",
                                          $$v
                                        )
                                      },
                                      expression: "taskDetail.productionName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "章节序号" },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.taskDetail.chapterSequenceNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "chapterSequenceNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "taskDetail.chapterSequenceNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "章节标题" },
                                },
                                [
                                  _c("a-input", {
                                    on: { blur: _vm.saveForm },
                                    model: {
                                      value: _vm.taskDetail.chapterName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "chapterName",
                                          $$v
                                        )
                                      },
                                      expression: "taskDetail.chapterName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "结算数量" },
                                },
                                [
                                  _vm.taskDetail.calcUnits
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.taskDetail.calcUnits.filter(
                                              (c) => {
                                                return (
                                                  _vm.calcUnits.findIndex(
                                                    (i) => i.type == c.type
                                                  ) > -1
                                                )
                                              }
                                            ),
                                            function (c, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("a-input-number", {
                                                    attrs: {
                                                      disabled: "",
                                                      min: 0,
                                                    },
                                                    on: { blur: _vm.saveForm },
                                                    model: {
                                                      value: c.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          c,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "c.value",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "5px",
                                                        "padding-right": "5px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getUnit(c.type)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._l(
                                            _vm.calcUnits.filter((c) => {
                                              return (
                                                _vm.taskDetail.calcUnits.findIndex(
                                                  (i) => i.type == c.type
                                                ) == -1
                                              )
                                            }),
                                            function (c, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("a-input-number", {
                                                    attrs: {
                                                      disabled: "",
                                                      min: 0,
                                                    },
                                                    on: { blur: _vm.saveForm },
                                                    model: {
                                                      value: c.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          c,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "c.value",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "5px",
                                                        "padding-right": "5px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getUnit(c.type)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "当前阶段" },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.taskDetail.stageName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "stageName",
                                          $$v
                                        )
                                      },
                                      expression: "taskDetail.stageName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "当前人员" },
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择人员",
                                        disabled:
                                          !_vm.isTongChou && !_vm.isAdmin,
                                        "option-filter-prop": "label",
                                      },
                                      on: { change: _vm.saveForm },
                                      model: {
                                        value: _vm.taskDetail.user.userId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.taskDetail.user,
                                            "userId",
                                            $$v
                                          )
                                        },
                                        expression: "taskDetail.user.userId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.getSelectUser(
                                        _vm.allUsers,
                                        _vm.taskDetail
                                      ),
                                      function (item) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: item.userId,
                                            attrs: {
                                              value: item.userId,
                                              label: item.userName,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "30px",
                                                height: "30px",
                                                "border-radius": "50%",
                                                "margin-right": "10px",
                                              },
                                              attrs: {
                                                src: item.avatar,
                                                alt: "",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.userName || "")
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "截止日期" },
                                },
                                [
                                  _c("a-date-picker", {
                                    attrs: {
                                      format: "YYYY-MM-DD HH:mm",
                                      valueFormat: "YYYY-MM-DD HH:mm",
                                      disabled: !_vm.isTongChou && !_vm.isAdmin,
                                      "show-time": {
                                        format: "HH:mm",
                                        defaultValue: _vm.moment(
                                          "23:59",
                                          "HH:mm"
                                        ),
                                      },
                                      placeholder: "请选择截止日期",
                                    },
                                    on: { change: _vm.saveForm },
                                    model: {
                                      value: _vm.taskDetail.deadline,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "deadline",
                                          $$v
                                        )
                                      },
                                      expression: "taskDetail.deadline",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "结算月份" },
                                },
                                [
                                  _c("a-month-picker", {
                                    attrs: {
                                      disabled: "",
                                      format: "YYYY-MM",
                                      "show-time": { format: "YYYY-MM" },
                                      valueFormat: "YYYY-MM",
                                      placeholder: "请选择结算月",
                                    },
                                    on: { change: _vm.saveForm },
                                    model: {
                                      value: _vm.taskDetail.settlementDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "settlementDate",
                                          $$v
                                        )
                                      },
                                      expression: "taskDetail.settlementDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "时间间隔" },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.taskDetail.timeInterval,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taskDetail,
                                          "timeInterval",
                                          $$v
                                        )
                                      },
                                      expression: "taskDetail.timeInterval",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  staticStyle: { display: "flex" },
                                  attrs: { label: "完成情况" },
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      disabled: "",
                                      value:
                                        _vm.taskDetail.finishStatus == "ON_TIME"
                                          ? "按时"
                                          : _vm.taskDetail.finishStatus ==
                                            "ADVANCE"
                                          ? "提前"
                                          : _vm.taskDetail.finishStatus ==
                                            "DELAY"
                                          ? "拖稿"
                                          : "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _vm.isDesktop()
                        ? _c("g-title", [_vm._v("历史记录")])
                        : _vm._e(),
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.radioValue,
                            callback: function ($$v) {
                              _vm.radioValue = $$v
                            },
                            expression: "radioValue",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v(
                              "\n                任务历史\n              "
                            ),
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v(
                              "\n                数据操作历史\n              "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.isDesktop() && _vm.radioValue == 1
                        ? _c(
                            "a-timeline",
                            { staticStyle: { "margin-top": "30px" } },
                            _vm._l(_vm.logList, function (item, index) {
                              return _c(
                                "a-timeline-item",
                                {
                                  key: index,
                                  staticStyle: { "font-size": "14px" },
                                },
                                [
                                  _vm._v(
                                    "(" +
                                      _vm._s(item.operationTime) +
                                      ")" +
                                      _vm._s(" ") +
                                      " " +
                                      _vm._s(
                                        item.userName + ": " + item.content
                                      )
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.isDesktop() && _vm.radioValue == 2
                        ? _c(
                            "a-timeline",
                            { staticStyle: { "margin-top": "30px" } },
                            _vm._l(_vm.logListTwo, function (item, index) {
                              return _c(
                                "a-timeline-item",
                                {
                                  key: index,
                                  staticStyle: { "font-size": "14px" },
                                },
                                [
                                  _vm._v(
                                    "(" +
                                      _vm._s(item.operationTime) +
                                      ")" +
                                      _vm._s(" ") +
                                      " " +
                                      _vm._s(
                                        item.userName + ": " + item.content
                                      )
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "a-col",
              {
                staticStyle: { "padding-top": "5vh" },
                attrs: { span: _vm.isDesktop() ? 14 : 24 },
              },
              [
                _vm.tabShow ||
                _vm.kpiShow ||
                _vm.moneyShow ||
                _vm.otherKpiShow ||
                _vm.remarkShow
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          height: "70vh",
                          "font-size": "14px",
                          "padding-left": "2.2vw",
                        },
                      },
                      [
                        _c(
                          "a-tabs",
                          {
                            on: { change: _vm.callback },
                            model: {
                              value: _vm.tab,
                              callback: function ($$v) {
                                _vm.tab = $$v
                              },
                              expression: "tab",
                            },
                          },
                          [
                            _vm.isCoefficientPar &&
                            _vm.tabShow &&
                            _vm.taskDetail.isLocal
                              ? _c(
                                  "a-tab-pane",
                                  {
                                    key: "1",
                                    attrs: {
                                      disabled:
                                        _vm.$refs.kpi && _vm.$refs.kpi.kpiDis,
                                      tab: "系数参数",
                                    },
                                  },
                                  [
                                    _c("coefficientPar", {
                                      ref: "coefficientPar",
                                      attrs: {
                                        systemTypes: _vm.systemTypes,
                                        params: _vm.params,
                                        taskDetail: _vm.taskDetail,
                                      },
                                      on: {
                                        setTabVis: _vm.setTabVis,
                                        setIsCoefficientPar:
                                          _vm.setIsCoefficientPar,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.tabShow && _vm.taskDetail.isLocal
                              ? _c(
                                  "a-tab-pane",
                                  {
                                    directives: [
                                      {
                                        name: "has",
                                        rawName: "v-has",
                                        value:
                                          "mytask:detail:button:coefficientPar",
                                        expression:
                                          "'mytask:detail:button:coefficientPar'",
                                      },
                                    ],
                                    key: "2",
                                    attrs: {
                                      disabled:
                                        _vm.tabDis ||
                                        (_vm.$refs.kpi && _vm.$refs.kpi.kpiDis),
                                      tab: "系数一览",
                                    },
                                  },
                                  [
                                    _c("rule", {
                                      ref: "rule",
                                      attrs: { taskDetail: _vm.taskDetail },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.kpiShow || _vm.moneyShow || _vm.otherKpiShow
                              ? _c(
                                  "a-tab-pane",
                                  {
                                    key: "3",
                                    attrs: {
                                      disabled:
                                        _vm.tabDis && _vm.taskDetail.isLocal,
                                      tab: "考核结算",
                                    },
                                  },
                                  [
                                    _c("kpi", {
                                      ref: "kpi",
                                      attrs: {
                                        taskDetail: _vm.taskDetail,
                                        systemTypes: _vm.systemTypes,
                                        params: _vm.params,
                                        formData: _vm.kpiFormData,
                                        moneyShow: _vm.moneyShow,
                                        kpiShow: _vm.kpiShow,
                                        rule: _vm.rule,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "a-tab-pane",
                              { key: "4" },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "tab" }, slot: "tab" },
                                  [
                                    _c(
                                      "a-badge",
                                      { attrs: { count: _vm.msgTotal } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "0.9vw",
                                            },
                                          },
                                          [_vm._v("备注")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("remark", {
                                  ref: "remark",
                                  attrs: {
                                    taskDetail: _vm.taskDetail,
                                    params: _vm.params,
                                  },
                                  on: { getRemarkList: _vm.getRemarkList },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }